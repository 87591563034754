import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/employees/actions";
import { store } from "redux/store";
import {
	getRequest,
	postRequest,
	patchRequest,
	putRequest,
	deleteRequest,
} from "helpers/axiosClient";
import {
	getCompanyUrl,
	getLocaleMessages,
	handleException,
	customAPIRequest,
	calculatePageNumber,
} from "redux/helper";
import {
	constructExtraColumn,
	showNotification,
	getRootUrl,
	convertMomentToString,
} from "components/utility/helper";
import {
	assetTypeTask,
	dateFormatDefault,
	defaultPage,
	dropdownListPerPage,
	notFoundCode,
} from "components/constants";
import { history } from "redux/store";
import { employeeTableTabs } from "components/constants";
import journeyActions from "redux/journeys/actions";
import assetActions from "redux/assets/actions";
import meetingActions from "redux/meetings/actions";
import userActions from "redux/settings/users/actions";
import {
	FILTERED_EMPLOYEES_PER_PAGE,
	FILTERED_EMPLOYEES_SORT_KEY,
	FILTERED_EMPLOYEES_SORT_ORDER,
} from "components/constants";

export function* fetchImportedList(params) {
	try {
		const {
				listCurrentPage,
				listSearchKey,
				listSortKey,
				listSortValue,
				listPerPage,
			} = store.getState().Employees,
			searchQuery = params.searchText || listSearchKey,
			page = params.page || listCurrentPage,
			sortKey = params.sortKey || listSortKey,
			sortOrder = params.sortOrder || listSortValue,
			perPage = params.perPage || listPerPage;

		let url = `${getCompanyUrl()}/imports?query[import_name_cont]=${encodeURI(
			searchQuery.trim(),
		)}&query[sorts][]=${sortKey} ${sortOrder}&query[sorts][]=id ${sortOrder}&page=${page}&per_page=${perPage}`;

		let { data } = yield call(() => getRequest(url));
		if (params.fromDashboard) {
			history.push({
				pathname: `/${getRootUrl()}/employees`,
				search: "employee_type=list",
			});
		}

		yield put({
			type: actions.FETCH_IMPORTED_LIST_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_EMPLOYEES_LIST_FAILURE });
		handleException(error);
	}
}

export function* fetchEmployeesList(params) {
	try {
		const {
				employeeCurrentPage,
				employeeSearchKey,
				employeeSortKey,
				employeeSortValue,
				employeePerPage,
				selectedListId,
			} = store.getState().Employees,
			page = params.page || employeeCurrentPage,
			searchQuery = params.searchText || employeeSearchKey,
			sortKey = params.sortKey || employeeSortKey,
			sortOrder = params.sortOrder || employeeSortValue,
			perPage = params.perPage || employeePerPage,
			importId = params.importId || selectedListId;

		let url = `${getCompanyUrl()}/employees?query[import_id_eq]=${
			importId ? importId : ""
		}&query[email_or_name_cont]=${encodeURIComponent(
			searchQuery.trim(),
		)}&query[sorts][]=${sortKey} ${sortOrder}&query[sorts][]=id ${sortOrder}&page=${page}&per_page=${perPage}`;

		let { data } = yield call(() => getRequest(url));
		if (params.addEmployee) {
			yield put({
				type: actions.SET_ADD_EMPLOYEES,
				payload: { employeeList: data.data, currentPage: params.page },
			});
		} else {
			yield put({
				type: actions.FETCH_EMPLOYEES_LIST_SUCCESS,
				payload: data.data,
			});
		}
	} catch (error) {
		yield put({ type: actions.FETCH_EMPLOYEES_LIST_FAILURE });
		handleException(error);
	}
}

export function* fetchFilteredEmployees(params) {
	try {
		const { filteredEmployeesPage, filterEmployeesKey } =
				store.getState().Employees,
			page = params.payload.page || filteredEmployeesPage,
			searchQuery = params.payload.key || filterEmployeesKey,
			sortKey = FILTERED_EMPLOYEES_SORT_KEY,
			sortOrder = FILTERED_EMPLOYEES_SORT_ORDER,
			perPage = FILTERED_EMPLOYEES_PER_PAGE,
			isRebuildingList = params.payload.isRebuildingList || false,
			isPaginationQuery = params.payload.isPaginationQuery || false,
			isSearchQuery = params.payload.isSearchQuery || false;

		if (!(!isRebuildingList && isSearchQuery && searchQuery === "")) {
			let url = `${getCompanyUrl()}/employees?query[email_or_name_cont]=${encodeURIComponent(
				searchQuery.trim(),
			)}&query[sorts][]=${sortKey} ${sortOrder}&query[sorts][]=id ${sortOrder}&page=${page}&per_page=${perPage}`;

			let { data } = yield call(() => getRequest(url));

			if (isRebuildingList) {
				yield put({
					type: actions.SET_FILTERED_EMPLOYEES,
					payload: {
						employees: data.data.employees,
						isPaginationQuery,
						currentPage: 1,
					},
				});
				yield put({
					type: actions.SET_DEFAULT_FILTERED_EMPLOYEES,
					payload: {
						employees: data.data.employees,
					},
				});
			} else {
				yield put({
					type: actions.SET_FILTERED_EMPLOYEES,
					payload: {
						employees: data.data.employees,
						isPaginationQuery,
						currentPage: isSearchQuery
							? 1
							: data.data.employees.length === 0
							? page - 1
							: page,
					},
				});
			}
		}

		yield put({
			type: isPaginationQuery
				? actions.SET_SCROLL_LOADER
				: actions.SET_LOADING_FILTERED_EMPLOYEES,
			payload: {
				value: false,
			},
		});
	} catch (error) {
		yield put({
			type: actions.SET_SCROLL_LOADER,
			payload: {
				value: false,
			},
		});
		yield put({
			type: actions.SET_LOADING_FILTERED_EMPLOYEES,
			payload: {
				value: false,
			},
		});
		handleException(error);
	}
}

export function* deleteImports() {
	try {
		const { selectedListKeys, totalLists, listPerPage, listCurrentPage } =
			store.getState().Employees;
		let url = `${getCompanyUrl()}/imports`;
		const { data } = yield call(() =>
			patchRequest(url, { ids: selectedListKeys }),
		);
		yield put({
			type: actions.UPDATE_IMPORTS_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		let pages = calculatePageNumber(totalLists, selectedListKeys, listPerPage),
			page = listCurrentPage;
		if (listCurrentPage > pages) {
			page = pages;
			yield put({ type: actions.SET_LIST_PAGINATION, payload: page });
		}
		yield put({
			type: actions.UPDATE_SELECTED_LIST,
			payload: [],
		});
		yield put({ type: actions.SET_IMPORTS_DELETE_LOADER, payload: false });
		yield put({ type: actions.FETCH_IMPORTED_LIST, page });
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.SET_IMPORTS_DELETE_LOADER, payload: false });
	}
}

export function* deleteEmployees() {
	try {
		const {
			selectedEmployeeKeys,
			totalEmployees,
			employeePerPage,
			employeeCurrentPage,
		} = store.getState().Employees;
		let url = `${getCompanyUrl()}/employees`;
		const { data } = yield call(() =>
			patchRequest(url, { ids: selectedEmployeeKeys }),
		);
		yield put({
			type: actions.UPDATE_SELECTED_EMPLOYEES,
			payload: [],
		});
		yield put({
			type: actions.UPDATE_EMPLOYEES_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		yield put({
			type: actions.UPDATE_IMPORTS_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		let pages = calculatePageNumber(
				totalEmployees,
				selectedEmployeeKeys,
				employeePerPage,
			),
			page = employeeCurrentPage;
		if (employeeCurrentPage > pages) {
			page = pages;
			yield put({ type: actions.SET_EMPLOYEE_PAGINATION, payload: page });
		}
		yield put({ type: actions.FETCH_EMPLOYEES_LIST, page });
		yield put({ type: actions.SET_EMPLOYEES_DELETE_LOADER, payload: false });
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		store.dispatch({
			type: actions.FETCH_FILTERED_EMPLOYEES,
			payload: {
				page: 1,
				key: "",
				isRebuildingList: true,
			},
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.SET_EMPLOYEES_DELETE_LOADER, payload: false });
	}
}

export function* getPreSignedURL(params) {
	try {
		const response = yield call(() =>
			postRequest("direct_uploads", {
				blob: params.payload.blob,
				isPublicAccessible: params.payload.isPublicAccessible || false,
			}),
		);
		yield put({
			type: actions.UPLOAD_TO_S3,
			payload: {
				URL: response.data.data.direct_upload.url,
				headers: response.data.data.direct_upload.headers,
				signed_id: response.data.data.signed_id,
				fileData: params.payload.fileData,
			},
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_PRE_SIGNED_URL_FAILURE,
		});
	}
}

export function* uploadToS3(params) {
	try {
		yield call(() => customAPIRequest({ ...params.payload }));
		yield put({
			type: actions.UPLOAD_TO_S3_SUCCESS,
		});
		yield put({
			type: actions.UPDATE_UPLOAD_STATUS,
			payload: params.payload.signed_id,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPLOAD_TO_S3_FAILURE,
		});
	}
}

export function* updateUploadStatus(params) {
	try {
		let data = {
			import_type: "employee",
			file: params.payload,
		};
		yield call(() => postRequest(`${getCompanyUrl()}/imports`, { ...data }));
		yield put({
			type: actions.UPDATE_UPLOAD_STATUS_SUCCESS,
		});
		const onClose = () => {
			store.dispatch({ type: actions.SET_FILE_UPLOADING_STATUS });
		};
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: getLocaleMessages("message.listUploadSuccess"),
			onClose: onClose,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_UPLOAD_STATUS_FAILURE,
		});
	}
}

export function* getExtraColumns() {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/tokens`));
		yield put({
			type: actions.GET_EMPLOYEE_EXTRA_COLUMNS_SUCCESS,
			payload: constructExtraColumn(response.data.data.tokens),
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_EMPLOYEE_EXTRA_COLUMNS_FAILURE,
		});
	}
}

export function* assignJourney(params) {
	try {
		const { data } = yield call(() =>
			putRequest(
				`${getCompanyUrl()}/journeys/${params.payload.journeyId}/assign`,
				params.payload.employee,
			),
		);
		yield put({
			type: actions.ASSIGN_JOURNEY_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.FETCH_EMPLOYEES_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.ASSIGN_JOURNEY_FAILURE,
		});
	}
}

export function* fetchJourney() {
	try {
		const { journeyPage, journeySearchKey } = store.getState().Employees;
		let url = `${getCompanyUrl()}/journeys?page=${journeyPage}&per_page=${dropdownListPerPage}&query[name_cont]=${encodeURI(
			journeySearchKey.trim(),
		)}&query[status_eq]=approved`;

		const { data } = yield call(() => getRequest(url));

		yield put({
			type: actions.FETCH_JOURNEY_SUCCESS,
			payload: data.data.journeys,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_JOURNEY_FAILURE,
		});
	}
}

export function* pauseEmployeeJourney() {
	try {
		const { selectedEmployeeKeys, removeEmployeeForSelectedJourneyId } =
			store.getState().Employees;
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${selectedEmployeeKeys[0]}/pause_journey`,
				{
					ids: removeEmployeeForSelectedJourneyId,
					pause: true,
				},
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.PAUSE_EMPLOYEES_FROM_JOURNEY_SUCCESS,
		});
		yield put({
			type: actions.FETCH_EMPLOYEES_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.PAUSE_EMPLOYEES_FROM_JOURNEY_FAILURE,
		});
	}
}

export function* resumeEmployeeJourney() {
	try {
		const { selectedEmployeeKeys, removeEmployeeForSelectedJourneyId } =
			store.getState().Employees;
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${selectedEmployeeKeys[0]}/pause_journey`,
				{
					ids: removeEmployeeForSelectedJourneyId,
					pause: false,
				},
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.RESUME_EMPLOYEES_FROM_JOURNEY_SUCCESS,
		});
		yield put({
			type: actions.FETCH_EMPLOYEES_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.RESUME_EMPLOYEES_FROM_JOURNEY_FAILURE,
		});
	}
}

export function* fetchEmployeeDetail() {
	try {
		const { currentEmployeeId } = store.getState().Employees;
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/employees/${currentEmployeeId}`),
		);
		yield put({
			type: actions.UPDATE_CURRENT_EMPLOYEE,
			payload: data.data.employee,
		});
		yield put({
			type: actions.FETCH_EMPLOYEE_DETAIL_SUCCESS,
			payload: data.data.employee,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_EMPLOYEE_DETAIL_FAILURE,
		});
	}
}

export function* updateEmployeeDetails(params) {
	try {
		const { selectedListId, currentEmployeeId } = store.getState().Employees;
		const { data } = yield call(() =>
			putRequest(
				`${getCompanyUrl()}/employees/${currentEmployeeId}?import_id=${
					selectedListId ? selectedListId : ""
				}`,
				params.payload,
			),
		);
		yield put({
			type: actions.UPDATE_EMPLOYEE_SUCCESS,
			payload: data.data.employee,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
			duration: 2,
		});
		store.dispatch({
			type: actions.FETCH_FILTERED_EMPLOYEES,
			payload: {
				page: 1,
				key: "",
				isRebuildingList: true,
			},
		});
	} catch (error) {
		const { employeeDetail } = store.getState().Employees;
		yield put({
			type: actions.UPDATE_CURRENT_EMPLOYEE,
			payload: employeeDetail,
		});
		handleException(error);
	}
}

export function* getTaskList() {
	try {
		const {
			employeeTaskListCurrentPage,
			employeeTaskListSortKey,
			employeeTaskListSortOrder,
			employeeTaskListPerPage,
			currentEmployeeId,
		} = store.getState().Employees;

		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/employees/${currentEmployeeId}/tasks?query[sorts]=created_at desc&query[sorts]=${employeeTaskListSortKey} ${employeeTaskListSortOrder}&page=${employeeTaskListCurrentPage}&per_page=${employeeTaskListPerPage}`,
			),
		);

		yield put({
			type: actions.GET_EMPLOYEE_TASK_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		yield put({ type: actions.GET_EMPLOYEE_TASK_LIST_FAILURE });
		handleException(error);
	}
}

export function* fetchAllTasks() {
	try {
		const { taskPage, taskSearchKey } = store.getState().Employees;

		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/tasks?page=${taskPage}&per_page=20&query[name_cont]=${encodeURI(
					taskSearchKey.trim(),
				)}`,
			),
		);

		yield put({
			type: actions.FETCH_ALL_TASKS_SUCCESS,
			payload: response.data.data.tasks,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_ALL_TASKS_FAILURE });
		handleException(error);
	}
}

export function* getEmployeeTaskDetails(params) {
	try {
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/employees/${params.payload.employeeId}/tasks/${
					params.payload.taskId
				}`,
			),
		);
		yield put({
			type: actions.GET_EMPLOYEE_TASK_DETAILS_SUCCESS,
			payload: response.data.data.task,
		});
	} catch (error) {
		yield put({ type: actions.GET_EMPLOYEE_TASK_DETAILS_FAILURE });
		handleException(error);
	}
}

export function* reOpenTask(params) {
	try {
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${params.payload.employeeId}/tasks/${
					params.payload.taskId
				}`,
				{
					employee_event: {
						due_date: convertMomentToString(
							params.payload.due_date,
							dateFormatDefault,
						),
						employee_status: "reopen",
						reason: params.payload.reason,
					},
				},
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.REOPEN_EMPLOYEE_TASK_SUCCESS,
			payload: response.data.data.task,
		});
	} catch (error) {
		yield put({ type: actions.REOPEN_EMPLOYEE_TASK_FAILURE });
		handleException(error);
	}
}

export function* deleteEmployeeTask(params) {
	try {
		const {
			employeeTaskListCurrentPage,
			employeeTaskListPerPage,
			employeeTaskTotalData,
		} = store.getState().Employees;
		const response = yield call(() =>
			deleteRequest(
				`${getCompanyUrl()}/employees/${params.payload.employeeId}/tasks/${
					params.payload.taskId
				}`,
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		history.push({
			pathname: `/${getRootUrl()}/list/${params.payload.importId}/employees/${
				params.payload.employeeId
			}`,
			search: "employee_type=employeeTask",
		});
		let pages = calculatePageNumber(
			employeeTaskTotalData,
			[params.payload.taskId],
			employeeTaskListPerPage,
		);
		if (employeeTaskListCurrentPage > pages) {
			yield put({
				type: actions.SET_EMPLOYEE_TASK_LIST_PAGINATION,
				payload: pages,
			});
		}
		yield put({
			type: actions.DELETE_EMPLOYEE_TASK_SUCCESS,
		});
	} catch (error) {
		yield put({ type: actions.DELETE_EMPLOYEE_TASK_FAILURE });
		handleException(error);
	}
}

export function* fetchMeetings() {
	try {
		const { meetingPage, meetingSearchKey } = store.getState().Employees;
		let url = `${getCompanyUrl()}/meetings?page=${meetingPage}&per_page=${dropdownListPerPage}&query[name_cont]=${encodeURI(
			meetingSearchKey.trim(),
		)}&query[sorts][]=id asc&query[meeting_type_eq]=general&query[status_not_eq]=cancel`;

		const { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_MEETING_SUCCESS,
			payload: data.data.meetings,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_MEETING_FAILURE,
		});
	}
}

export function* assignMeeting(params) {
	try {
		const { employeeActiveTab } = store.getState().Employees;
		const { data } = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/meetings/${
					params.payload.meetingId
				}/assign`,
				params.payload.employee,
			),
		);
		yield put({
			type: actions.ASSIGN_MEETING_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		if (employeeActiveTab === employeeTableTabs.in_progress) {
			yield put({
				type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST,
			});
		} else {
			yield put({
				type: actions.FETCH_EMPLOYEES_LIST,
			});
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.ASSIGN_MEETING_FAILURE,
		});
	}
}

export function* removeEmployeeFromJourney(params) {
	try {
		const { employeeActiveTab } = store.getState().Employees;
		const { data } = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/journeys/remove_employee`,
				params.payload,
			),
		);
		yield put({
			type: actions.REMOVE_EMPLOYEE_FROM_JOURNEY_SUCCESS,
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		if (employeeActiveTab === employeeTableTabs.in_progress) {
			yield put({
				type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST,
			});
		} else {
			yield put({
				type: actions.FETCH_EMPLOYEES_LIST,
			});
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.REMOVE_EMPLOYEE_FROM_JOURNEY_FAILURE,
		});
	}
}

export function* getInProgressEmployeeList(params) {
	try {
		const {
				inProgressEmployeeCurrentPage,
				listSearchKey,
				listSortKey,
				listSortValue,
				listPerPage,
			} = store.getState().Employees,
			searchQuery = params.searchText || listSearchKey,
			page = params.page || inProgressEmployeeCurrentPage,
			sortKey = params.sortKey || listSortKey,
			sortOrder = params.sortOrder || listSortValue,
			perPage = params.perPage || listPerPage;
		let url = `${getCompanyUrl()}/employees?query[status_eq]=assigned&query[sorts]=created_at%20${sortOrder}&query[sorts]=${sortKey}&query[email_or_name_cont]=${encodeURIComponent(
			searchQuery.trim(),
		)}&page=${page}&per_page=${perPage}`;
		let { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST_FAILURE,
		});
	}
}

export function* deleteInProgressEmployees() {
	try {
		const {
			selectedInProgressEmployeeListKeys,
			inProgressEmployeeTotalCount,
			listPerPage,
			inProgressEmployeeCurrentPage,
		} = store.getState().Employees;
		let url = `${getCompanyUrl()}/employees`;
		const { data } = yield call(() =>
			patchRequest(url, { ids: selectedInProgressEmployeeListKeys }),
		);
		yield put({
			type: actions.UPDATE_IMPORTS_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		let pages = calculatePageNumber(
				inProgressEmployeeTotalCount,
				selectedInProgressEmployeeListKeys,
				listPerPage,
			),
			page = inProgressEmployeeCurrentPage;
		if (inProgressEmployeeCurrentPage > pages) {
			page = pages;
			yield put({
				type: actions.SET_INPROGRESS_EMPLOYEE_LIST_PAGINATION,
				payload: page,
			});
		}
		yield put({
			type: actions.UPDATE_INPROGRESS_EMPLOYEE_SELECTED_LIST,
			payload: [],
		});
		yield put({ type: actions.SET_IMPORTS_DELETE_LOADER, payload: false });
		yield put({ type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST, page });
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
		yield put({ type: actions.SET_IMPORTS_DELETE_LOADER, payload: false });
	}
}

export function* pauseInProgressEmployeeJourney() {
	try {
		const {
			selectedInProgressEmployeeListKeys,
			removeEmployeeForSelectedJourneyId,
		} = store.getState().Employees;
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${
					selectedInProgressEmployeeListKeys[0]
				}/pause_journey`,
				{
					pause: true,
					ids: removeEmployeeForSelectedJourneyId,
				},
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.PAUSE_EMPLOYEES_FROM_JOURNEY_SUCCESS,
		});
		yield put({
			type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.PAUSE_EMPLOYEES_FROM_JOURNEY_FAILURE,
		});
	}
}

export function* resumeInProgressEmployeeJourney() {
	try {
		const {
			selectedInProgressEmployeeListKeys,
			removeEmployeeForSelectedJourneyId,
		} = store.getState().Employees;
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/${
					selectedInProgressEmployeeListKeys[0]
				}/pause_journey`,
				{
					pause: false,
					ids: removeEmployeeForSelectedJourneyId,
				},
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.RESUME_EMPLOYEES_FROM_JOURNEY_SUCCESS,
		});
		yield put({
			type: actions.FETCH_INPROGRESS_EMPLOYEE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.RESUME_EMPLOYEES_FROM_JOURNEY_FAILURE,
		});
	}
}

export function* assignTask(params) {
	try {
		const { employeeType } = store.getState().Employees;
		const { data } = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/employees/tasks/${params.payload.taskId}/assign`,
				params.payload.employee,
			),
		);
		yield put({
			type: actions.ASSIGN_TASK_SUCCESS,
		});
		store.dispatch({
			type: userActions.ASSIGN_TASK_DRAWER_VISIBLE,
			payload: false,
		});
		yield put({
			type: actions.STORE_SELECTED_TASK_DETAILS,
			payload: null,
		});
		yield put({
			type: actions.EMPLOYEES_SET_TASK_PAGE,
			payload: defaultPage,
		});
		yield put({
			type: actions.EMPLOYEES_SET_TASK_SEARCH,
			payload: "",
		});
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		if (employeeType === "import") {
			yield put({ type: actions.UPDATE_SELECTED_EMPLOYEES, payload: [] });
		} else if (employeeType === "inProgress") {
			yield put({
				type: actions.UPDATE_INPROGRESS_EMPLOYEE_SELECTED_LIST,
				payload: [],
			});
		}
		yield put({
			type: journeyActions.UPDATE_SELECTED_ASSET,
			payload: {},
		});
		yield put({
			type: actions.FETCH_EMPLOYEES_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.ASSIGN_TASK_FAILURE,
		});
		yield put({
			type: journeyActions.UPDATE_SELECTED_ASSET,
			payload: {},
		});
	}
}

export function* fetchImportDetails(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/imports/${params.importId}`),
		);
		yield put({
			type: actions.FETCH_IMPORT_DETAILS_SUCCESS,
			payload: data.data.import,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_IMPORT_DETAILS_FAILURE,
		});
	}
}

export function* addManualEmployee(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/employees`, { employee: params.payload }),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.ADD_MANUAL_EMPLOYEE_SUCCESS,
		});
		yield put({
			type: actions.FETCH_EMPLOYEES_LIST,
		});
		if (Object.keys(params.payload.extra_fields)?.length) {
			yield put({ type: actions.GET_EMPLOYEE_EXTRA_COLUMNS });
		}
		store.dispatch({
			type: actions.FETCH_FILTERED_EMPLOYEES,
			payload: {
				page: 1,
				key: "",
				isRebuildingList: true,
			},
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.ADD_MANUAL_EMPLOYEE_FAILURE,
		});
	}
}

export function* getEmployeeActivityLog(params) {
	try {
		const {
				currentEmployeeId,
				activityLogPage,
				activityFromDate,
				activityToDate,
			} = store.getState().Employees,
			{ data } = yield call(() =>
				getRequest(
					`${getCompanyUrl()}/employees/${currentEmployeeId}/activities?page=${activityLogPage}&query[created_at_gteq]=${activityFromDate}&query[created_at_lteq]=${activityToDate}`,
				),
			);
		yield put({
			type: actions.GET_EMPLOYEE_ACTIVITY_LOG_SUCCESS,
			payload: data.data.activities,
			infiniteScroll: params.infiniteScroll,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_EMPLOYEE_ACTIVITY_LOG_FAILURE,
		});
	}
}

export function* getActivityDetails(params) {
	try {
		const { data } = yield call(() =>
				getRequest(`${getCompanyUrl()}/${params.url}`),
			),
			activityType =
				data.data[params.assetType === assetTypeTask ? "task" : "asset"];
		yield put({
			type: assetActions.SET_PREVIEW_DATA,
			payload: {
				type: params.assetType,
				name: activityType.name,
				subject: activityType.subject,
				content: activityType.content,
				files: activityType.files,
			},
		});
		yield put({ type: assetActions.SET_PREVIEW_LOADER, payload: false });
	} catch (error) {
		handleException(error);
		if (error.response.status === notFoundCode) {
			yield put({
				type: assetActions.SET_PREVIEW_DRAWER_VISIBLE,
				payload: false,
			});
		}
		yield put({ type: assetActions.SET_PREVIEW_LOADER, payload: false });
	}
}

export function* getMeetingDetails(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/meetings/${params.meetingId}`),
		);
		yield put({ type: assetActions.SET_PREVIEW_LOADER, payload: true });
		const {
			id,
			description,
			name,
			remind_before,
			scheduled_at,
			location,
			duration,
			custom_emails,
			attendies,
			from,
			to,
			reminder,
		} = data.data.meeting;
		yield put({
			type: meetingActions.SET_MEETING_DATA,
			payload: {
				name: name,
				id: id,
				description: description,
				remindBefore: remind_before ? remind_before : undefined,
				scheduledAt: scheduled_at,
				location,
				duration,
				customEmails: custom_emails,
				employeeIds: attendies.reduce((attendees, attendee) => {
					if (attendee.employee_id) {
						return [...attendees, attendee.employee_id.toString()];
					}
					return attendees;
				}, []),
				from,
				to,
				reminder,
			},
		});
		yield put({ type: assetActions.SET_PREVIEW_LOADER, payload: false });
	} catch (error) {
		handleException(error);
		if (error.response.status === notFoundCode) {
			yield put({
				type: meetingActions.SET_MEETINGS_DRAWER_VISIBLE,
				payload: {
					visible: false,
				},
			});
		}
		yield put({ type: assetActions.SET_PREVIEW_LOADER, payload: false });
	}
}

export function* resendEmployeeInvite(params) {
	try {
		const { data } = yield call(() =>
			postRequest(
				`${getCompanyUrl()}/employees/${params.payload}/resend_invite_email`,
			),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: data.data.message,
		});
		yield put({ type: actions.RESEND_EMPLOYEE_INVITE_SUCCESS });
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.RESEND_EMPLOYEE_INVITE_SUCCESS,
		});
	}
}

export function* fetchTimezone() {
	try {
		const timezoneResponse = yield call(() => getRequest("timezones"));
		yield put({
			type: actions.FETCH_TIMEZONE_SUCCESS,
			payload: timezoneResponse.data.data.timezones,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchSlackUsers() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/slacks/fetch_slack_users`),
		);

		yield put({
			type: actions.SET_SLACK_USERS,
			payload: response.data.data.slack_users,
		});
	} catch (error) {
		handleException(error);
	}
}
export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_IMPORTED_LIST, fetchImportedList),
		takeEvery(actions.FETCH_EMPLOYEES_LIST, fetchEmployeesList),
		takeEvery(actions.DELETE_IMPORTS, deleteImports),
		takeEvery(actions.DELETE_EMPLOYEES, deleteEmployees),
		takeEvery(actions.GET_PRE_SIGNED_URL, getPreSignedURL),
		takeEvery(actions.UPLOAD_TO_S3, uploadToS3),
		takeEvery(actions.UPDATE_UPLOAD_STATUS, updateUploadStatus),
		takeEvery(actions.GET_EMPLOYEE_EXTRA_COLUMNS, getExtraColumns),
		takeEvery(actions.ASSIGN_JOURNEY, assignJourney),
		takeEvery(actions.FETCH_JOURNEY, fetchJourney),
		takeEvery(actions.PAUSE_EMPLOYEES_FROM_JOURNEY, pauseEmployeeJourney),
		takeEvery(actions.RESUME_EMPLOYEES_FROM_JOURNEY, resumeEmployeeJourney),
		takeEvery(actions.FETCH_EMPLOYEE_DETAIL, fetchEmployeeDetail),
		takeEvery(actions.UPDATE_EMPLOYEE_DETAILS, updateEmployeeDetails),
		takeEvery(actions.GET_EMPLOYEE_TASK_LIST, getTaskList),
		takeEvery(actions.GET_EMPLOYEE_TASK_DETAILS, getEmployeeTaskDetails),
		takeEvery(actions.REOPEN_EMPLOYEE_TASK, reOpenTask),
		takeEvery(actions.DELETE_EMPLOYEE_TASK, deleteEmployeeTask),
		takeEvery(actions.FETCH_MEETING, fetchMeetings),
		takeEvery(actions.ASSIGN_MEETING, assignMeeting),
		takeEvery(actions.REMOVE_EMPLOYEE_FROM_JOURNEY, removeEmployeeFromJourney),
		takeEvery(
			actions.FETCH_INPROGRESS_EMPLOYEE_LIST,
			getInProgressEmployeeList,
		),
		takeEvery(actions.DELETE_INPROGRESS_EMPLOYEE, deleteInProgressEmployees),
		takeEvery(
			actions.PAUSE_INPROGRESS_EMPLOYEES_FROM_JOURNEY,
			pauseInProgressEmployeeJourney,
		),
		takeEvery(
			actions.RESUME_INPROGRESS_EMPLOYEES_FROM_JOURNEY,
			resumeInProgressEmployeeJourney,
		),
		takeEvery(actions.ASSIGN_TASK, assignTask),
		takeEvery(actions.FETCH_ALL_TASKS, fetchAllTasks),
		takeEvery(actions.FETCH_IMPORT_DETAILS, fetchImportDetails),
		takeEvery(actions.ADD_MANUAL_EMPLOYEE, addManualEmployee),
		takeEvery(actions.GET_EMPLOYEE_ACTIVITY_LOG, getEmployeeActivityLog),
		takeEvery(actions.GET_ACTIVITY_MODULE_DETAILS, getActivityDetails),
		takeEvery(actions.GET_MEETING_DETAILS, getMeetingDetails),
		takeEvery(actions.RESEND_EMPLOYEE_INVITE, resendEmployeeInvite),
		takeEvery(actions.FETCH_SLACK_USERS, fetchSlackUsers),
		takeEvery(actions.FETCH_FILTERED_EMPLOYEES, fetchFilteredEmployees),
	]);
}
