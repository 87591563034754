import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/dashboard/actions";
import { getRequest } from "helpers/axiosClient";
import { getCompanyUrl, handleException } from "redux/helper";
import { reportsDateFormat } from "components/constants";
import moment from "moment";

export function* fetchDashboard(params) {
	try {
		let url = `${getCompanyUrl()}/dashboard`;
		let { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_DASHBOARD_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_DASHBOARD_FAILURE });
		handleException(error);
	}
}

export function* fetchDashboardEngagementChart(params) {
	try {
		let getDataTo = moment()
				.add(params.after, "week")
				.format(reportsDateFormat),
			getDataFrom = moment()
				.subtract(params.before, "week")
				.format(reportsDateFormat),
			url = `${getCompanyUrl()}/dashboards/engagement_graph?query[start_date_gteq]=${getDataFrom}&query[start_date_lteq]=${getDataTo}`;
		let { data } = yield call(() => getRequest(url));
		yield put({
			type: actions.FETCH_DASHBOARD_ENGAGEMENT_CHART_SUCCESS,
			payload: data.data.engagement_graph,
		});
	} catch (error) {
		yield put({ type: actions.FETCH_DASHBOARD_FAILURE });
		handleException(error);
	}
}

export function* getEngagementScoreChartData(params) {
	try {
		let getDataTo = moment().subtract(1, "days").format(reportsDateFormat),
			getDataFrom = moment().subtract(30, "days").format(reportsDateFormat),
			chartData = "",
			scoreGrowth = false,
			lastDayScore = "";
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/dashboards/engagement_score?query[updated_at_gteq]=${getDataFrom}&query[updated_at_lteq]=${getDataTo}&query[status_eq]=assigned`,
			),
		);
		chartData = response.data.data.engagement_score_graph;
		// need to compare the last 2 days score from filter range for find scoreGrowth had increase or decrease.
		if (chartData.length >= 2) {
			chartData = chartData.filter(
				(value, index) => index > chartData.length - 3,
			);
			if (chartData[0].score <= chartData[1].score) {
				scoreGrowth = true;
			}
		}
		// if chartdata date preset todays only means need to show score because yesterday score not available.
		lastDayScore =
			chartData.length === 2
				? chartData[1].score
				: chartData.length === 1
				? chartData[0].score
				: 0;
		yield put({
			type: actions.FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_SUCCESS,
			payload: {
				chart: response.data.data.engagement_score_graph,
				engagementScore: lastDayScore,
				scoreGrowth: scoreGrowth,
			},
		});
		yield put({ type: actions.CHART_LOADER, payload: false });
	} catch (error) {
		yield put({
			type: actions.FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA_FAILURE,
		});
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.FETCH_DASHBOARD, fetchDashboard),
		takeEvery(
			actions.FETCH_DASHBOARD_ENGAGEMENT_CHART,
			fetchDashboardEngagementChart,
		),
		takeEvery(
			actions.FETCH_DASHBOARD_ENGAGEMENT_SCORE_CHART_DATA,
			getEngagementScoreChartData,
		),
	]);
}
